import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import AnySleepPosition from '../../src/Icons/AnySleepPosition';
import BackSleeper from '../../src/Icons/BackSleeper';
import SideSleeper from '../../src/Icons/SideSleeper';
import StomachSleeper from '../../src/Icons/StomachSleeper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <AnySleepPosition width={283} height={60} />\n  <BackSleeper width={283} height={60} />\n</Row>\n<Row>\n  <SideSleeper width={283} height={60} />\n  <StomachSleeper width={283} height={60} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      AnySleepPosition,
      BackSleeper,
      SideSleeper,
      StomachSleeper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <AnySleepPosition width={283} height={60} mdxType="AnySleepPosition" />
    <BackSleeper width={283} height={60} mdxType="BackSleeper" />
  </Row>
  <Row mdxType="Row">
    <SideSleeper width={283} height={60} mdxType="SideSleeper" />
    <StomachSleeper width={283} height={60} mdxType="StomachSleeper" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      